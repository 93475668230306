import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

export default function legalTemplate({ pageContext }) {
	const { markdownRemark } = pageContext; // data.markdownRemark holds your post data
	const { frontmatter, html } = markdownRemark;
	return (
		<Layout>
			<SEO pageTitle={frontmatter.title} description={frontmatter.description} />

			<div className="w-full px-6 mt-20 lg:my-32 lg:mx-auto lg:w-1/2">
				<h1 className="my-10 text-3xl border-b-2">{frontmatter.title}</h1>
				<div className="">
					<div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
				</div>
			</div>
		</Layout>
	);
}
